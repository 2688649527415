/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

const socialName = `github`;
const socialBaseUrl = `https://twitter.com/`;

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          description
          socialLinks {
            name
            url
          }
        }
      }
    }
  `)

  const author = data.site.siteMetadata?.author
  const socialLink = data.site.siteMetadata?.socialLinks.filter(sl => sl.name === socialName)
  const url = socialLink ? socialLink[0].url : socialBaseUrl

  let description  = data.site.siteMetadata?.description;
  return (
    <div className="bio">
      {author?.name && (
        <p>
          { description }.
          <br />
          Maintained by {` `}
          <a href={url}>
            {author.name}
          </a>.
        </p>
      )}
    </div>
  )
}

export default Bio
